import PropTypes from "prop-types";

import SocialNetwork from "../../SocialsNetwork";

import { createClassName } from "../../../helpers";
import "./style.scss";

const cn = createClassName("social-bar");

const SocialBar = (props) => (
  <div
    className={`${cn({ wrap: true })} ${props.className}`}
    id="bottom-social-bar"
  >
    <SocialNetwork {...props} />
  </div>
  );

SocialBar.defaultProps = {
  className: "",
};

SocialBar.propTypes = {
  className: PropTypes.string,
};

export default SocialBar;
