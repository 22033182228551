import { useState } from "react";
import PropTypes from "prop-types";
import { SlideDown } from "react-slidedown/lib/slidedown";

import Button from "../../Buttons/Common";
import CommentForm from "../../Forms/_comments";

import { createClassName } from "../../../helpers";
import "./style.scss";

const cn = createClassName("reply-button");

const ReplyButton = (props) => {
  const [isReply, setReply] = useState(false);
  return (
    <SlideDown className={cn("slide-down")}>
      <Button
        color="white"
        className={cn("wrap")}
        aria-label="Reply button"
        handler={() => setReply(!isReply)}
      >
        {props.text}
      </Button>
      <span className={cn("wrap")}>{props.date}</span>
      {isReply && (
        <div className={cn("form-wrap", { visible: isReply })}>
          <CommentForm parentId={props.parentId} commentableType={props.commentableType} commentableId={props.commentableId} />
        </div>
      )}
    </SlideDown>
  );
};

ReplyButton.propTypes = {
  date: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  parentId: PropTypes.number.isRequired,
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
};

export default ReplyButton;
