import { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next/dist/es/useTranslation";

import Question from "../_question";
import ReplyButton from "../_replyButton";
import { LocaleContext } from "../../../layouts/LocaleContext";

import { createClassName, commentLanguage } from "../../../helpers";
import "./style.scss";

const cn = createClassName("comment-content");

const CommentContent = (props) => {
  const { t } = useTranslation("common");
  const { siteLanguage } = useContext(LocaleContext);

  const formatDate = (value) => {
    const firsMonthLetter = value[0];
    const changeFirsMonthLetter = value.replace(
      firsMonthLetter,
      firsMonthLetter.toUpperCase(),
    );
    return changeFirsMonthLetter.replace("at", t("at"));
  };

  return props.response.map((comment) => (
    commentLanguage(comment.content) === siteLanguage && (
      <Question
        key={comment.id__normalized}
        logo={comment.avatarUrl || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}
        type={comment.parentId > 0 && "answer"}
        className={cn("wrap")}
      >
        <div className={cn({ answer: true })}>
          <div className={cn("text", { wrap: true })}>
            <span>
              <strong className={cn("text", { name: true })}>
                {comment.author}
              </strong>
            </span>
            <span
              className={cn("text", { content: true })}
              dangerouslySetInnerHTML={{ __html: comment.content }}
            />
          </div>
          <ReplyButton
            date={formatDate(comment.date)}
            text={props.replyButtonText}
            parentId={comment.id__normalized}
            commentableType={props.commentableType}
            commentableId={props.commentableId}
          />
        </div>
      </Question>
    )
  ));
};

CommentContent.propTypes = {
  response: PropTypes.arrayOf(PropTypes.object).isRequired,
  replyButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
};

export default CommentContent;
