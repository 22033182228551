import "./style.scss";

import { useContext } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import { commentLanguage, createClassName, sortComments } from "../../helpers";
import CommentContent from "./_commentContent";
import CommentForm from "../Forms/_comments";
import { LocaleContext } from "../../layouts/LocaleContext";
import Title from "../Title";

const cn = createClassName("comments-block");

const Comments = (props) => {
  const { t } = useTranslation("strings");
  const { siteLanguage } = useContext(LocaleContext);

  const commentsForPage = (array) => sortComments(array).filter(
    (comment) => commentLanguage(comment.content) === siteLanguage,
  );

  props.countComments(commentsForPage(props.comments).length);

  return (
    <div className={`${cn("wrap")} ${props.className}`}>
      <Title level={3} className={cn("title")}>
        {props.title ? props.title : t("questions-or-comments")}
      </Title>
      <CommentForm classNameForm={cn("form")} commentableType={props.commentableType} commentableId={props.commentableId} />
      <div className={cn("comments")}>
        <CommentContent
          response={commentsForPage(props.comments)}
          replyButtonText={t("common:reply")}
          commentableType={props.commentableType}
          commentableId={props.commentableId}
        />
      </div>
    </div>
  );
};

Comments.defaultProps = {
  title: "",
  className: "",
  countComments: () => { },
};

Comments.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
  className: PropTypes.string,
  countComments: PropTypes.func,
};

export default Comments;
