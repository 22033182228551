import PropTypes from "prop-types";
import Icon from "./index";

const AppSource = (props) => Icon({ ...props, iconName: "MicrosoftAppSource_Large" });

AppSource.defaultProps = {
  isAriaHidden: false,
  alt: "XLTools online apps",
};

AppSource.propTypes = {
  alt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default AppSource;
