import PropTypes from "prop-types";
import Icon from "./index";

const SocialIcon = (props) => Icon({
    ...props,
    dir: "socialIcons",
    iconName: props.icon,
  });

SocialIcon.defaultProps = {
  isAriaHidden: true,
};

SocialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default SocialIcon;
