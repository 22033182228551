import "./style.scss";

import PropTypes from "prop-types";
import LinkOnPage from "../../Links/LinkOnPage";
import Mark from "../../Marker";
import Meta from "../_meta";
import Title from "../../Title";
import { createClassName } from "../../../helpers";
import { OnlineSalesImage } from "../../OnlineSales/Image";

const cn = createClassName("preview-post");

const getPostLink = ({ slug }) => `/blog/${slug}`;

const PreviewPost = props => (
  <article aria-label={props.title} key={props.id}>
    <section>
      <LinkOnPage
        className={cn("img", { wrap: true })}
        color="default"
        href={getPostLink(props)}
      >
        <OnlineSalesImage
          onlineSalesImage={props.image}
          alt={props.title}
          className={cn("img")}
        />
      </LinkOnPage>
      <div className={cn("category")}>
        {/* eslint-disable-next-line react/prop-types */}
        <Mark text={props.category} color="gray" />
      </div>
      <LinkOnPage
        className={cn("link")}
        color="default"
        href={getPostLink(props)}
      >
        <Title level={4} weight="medium" className={cn("title")}>
          {props.title}
        </Title>
      </LinkOnPage>
      <Meta
        // eslint-disable-next-line react/prop-types
        meta={props.defaultData[0].dataList}
        className={cn("data", { wrap: true })}
        itemClassName={cn("data", { meta: true })}
        whiteIcon={false}
      />
    </section>
  </article>
);
PreviewPost.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default PreviewPost;
