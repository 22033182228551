import { useState } from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazy-load";

import { createClassName } from "../../../helpers";
import "./style.scss";

const Question = ({
 children, logo, className, type,
}) => {
  const cn = createClassName("comment");
  const classNameAnswer = cn({ block: true, answer: true });
  const classNameQuestion = cn({ block: true, question: true });

  const classNameSection = (type) => (type === "answer" ? classNameAnswer : classNameQuestion);

  const [isVisible, setIsVisible] = useState(false);

  function handleLoaded() {
    setIsVisible(true);
  }

  return (
    <section className={`${className} ${classNameSection(type)}`}>
      <div className={cn({ logo: "wrap" })}>
        <div className={cn({ logo: "placeholder" }) + (isVisible ? " --loaded" : "")}>
          <LazyLoad
            once
            height={48}
            width={48}
            offsetVertical={400}
            throttle={100}
            onContentVisible={handleLoaded}
          >
            <img src={logo} className={cn({ logo: true })} />
          </LazyLoad>
        </div>
      </div>
      <div className={cn({ block: "content" })}>{children}</div>
    </section>
  );
};

Question.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  logo: PropTypes.string,
};

export default Question;
