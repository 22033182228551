import "./style.scss";

import {
  colorWord,
  contactUs,
  insideLink,
  li,
  marker,
  note,
  ol,
  outsideLink,
  paragraph,
  renderCom,
  table,
  tableColorWord,
  td,
  ul,
  video,
  downloadFileLink,
  arrowIcon,
  introductionText,
} from "../../components/ForMarkDownComponents";

import AppSource from "../../components/Icons/AppSource";
import Cheked from "../../components/Icons/Cheked";
import Cross from "../../components/Icons/Cross";
import Heading from "../../components/Heading";
import LinkOnResource from "../../components/Links/LinkOnResource";
import { createClassName } from "../../helpers";

const cn = createClassName("post");

const postHeading = ({ children, index }) => (
  <Heading
    level={2}
    fontLevel={4}
    weight="medium"
    className={cn("page", { title: true, "first-title": index === 0 })}
  >
    {children}
  </Heading>
);

const postTestimonial = ({ children, author }) => (
  <figure className={cn("page", { testimonial: true })}>
    <blockquote
      className={cn("page", {
        testimonial: "text",
      })}
    >
      {children}
    </blockquote>
    {author && (
      <figcaption className={cn("page", { testimonial: "author" })}>
        {author}
      </figcaption>
    )}
  </figure>
);

const postMterials = ({ children }) => (
  <div className={cn("page", { materials: true })}>{children}</div>
);

const postLi = ({ children, bottomReduce = true }) => li({ children, bottomReduce });

const postMarker = ({ color = "light-blue", children, syntax = false }) => marker({ color, syntax, children });

const compareTable = ({ children }) => (
  <div className={cn("compare-table", { wrap: true })}>{children}</div>
);

const variant = ({ children, check = false }) => (
  <div className={cn("compare-table", { block: true })}>
    {check ? (
      <Cross colorIcon="red" className={cn("compare-table", { icon: true })} />
    ) : (
      <Cheked className={cn("compare-table", { icon: true })} />
    )}
    {children}
  </div>
);

const compareLink = ({ alt, href }) => (
  <div className={cn("compare-table", { button: "wrap" })}>
    <LinkOnResource
      href={href}
      className={cn("compare-table", { button: true })}
      isTarget
      rel="nofollow noopener"
    >
      <AppSource
        className={cn("compare-table", { button: "icon" })}
        alt={alt}
      />
    </LinkOnResource>
  </div>
);

export const postRenderComToolkit = {
  ul,
  ol,
  li: postLi,
  h2: postHeading,
  p: paragraph,
  // img: image,
  testimonial: postTestimonial,
  note,
  video,
  table,
  mark: postMarker,
  materials: postMterials,
  td,
  compare: compareTable,
  variant,
  arrow: arrowIcon,
  "color-word": colorWord,
  "red-color-word": tableColorWord,
  "outside-link": outsideLink,
  "inside-link": insideLink,
  "compare-link": compareLink,
  "contact-us": contactUs,
  "download-link": downloadFileLink,
  "introduction-text": introductionText,
};

export const postRenderCom = renderCom(postRenderComToolkit);
