import PropTypes from "prop-types";
import Icon from "./index";

const Cross = (props) => Icon({
    ...props,
    iconName: `cross-${props.colorIcon}`,
  });

Cross.defaultProps = {
  colorIcon: "white",
  isAriaHidden: true,
  alt: "cross",
};

Cross.propTypes = {
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
  colorIcon: PropTypes.string,
};

export default Cross;
