import PropTypes from "prop-types";
import Icon from "./index";

const Checked = (props) => Icon({ ...props, iconName: "status-ok" });

Checked.defaultProps = {
  isAriaHidden: false,
  alt: "check",
};

Checked.propTypes = {
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default Checked;
