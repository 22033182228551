/* eslint-disable react/prop-types */
import { useMemo } from "react";
import { graphql } from "gatsby";
import { Redirect } from "@reach/router";

import PostPage from "../../layouts/PostPage";
import { useOnlineSalesImage } from "../../components/OnlineSales/Image";

const BlogPostPage = ({ data }) => {
  const { htmlAst, frontmatter } = data.onlineSalesPost.childMarkdownRemark;
  const comments = data.allPagesComments;
  const imageRenderer = useOnlineSalesImage(data.onlineSalesPost.images);

  const relatedPosts = useMemo(
    () =>
      // Getting random 2 posts
      [...data.relatedPosts.nodes]
        .sort(() => (Math.floor(Math.random() * 10) > 5 ? 1 : -1))
        .slice(0, 2),
    [data.relatedPosts.nodes]
  );

  return (
    <PostPage
      htmlAst={htmlAst}
      frontmatter={frontmatter}
      allPagesComments={comments}
      imageRenderer={imageRenderer}
      relatedPosts={relatedPosts}
    />
  );
};

const BlogPostTemplate = props => {
  const { data } = props;

  if (!data.onlineSalesPost) {
    return <Redirect to="/404" noThrow replace />;
  }

  return <BlogPostPage {...props} />;
};

export default BlogPostTemplate;

export const query = graphql`
  query getPost(
    $slug: String!
    $siteLanguage: String!
    $commentsPage: String!
  ) {
    onlineSalesPost(slug: { eq: $slug }, language: { eq: $siteLanguage }) {
      id
      slug
      language
      images {
        staticPath
        file {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              quality: 100
            )
          }
        }
      }
      childMarkdownRemark {
        htmlAst
        frontmatter {
          path
          seo {
            description
            link
            title
          }
          coverImageUrl
          category
          author
          bannerTitle
          preview
          defaultData {
            dataList {
              date
              time
            }
          }
          hashtags
        }
      }
    }
    relatedPosts: allOnlineSalesPost(
      filter: { slug: { ne: $slug }, language: { eq: $siteLanguage } }
    ) {
      nodes {
        id
        slug
        language
        coverImage {
          file {
            childImageSharp {
              gatsbyImageData(
                width: 350
                layout: CONSTRAINED
                placeholder: BLURRED
                quality: 100
              )
            }
          }
        }
        childMarkdownRemark {
          frontmatter {
            title
            category
            defaultData {
              dataList {
                date
                time
              }
            }
          }
        }
      }
    }
    allPagesComments(
      sort: { fields: id__normalized, order: ASC }
      filter: { pageKey: { eq: $commentsPage } }
    ) {
      nodes {
        id__normalized
        author
        date(formatString: "MMMM D, YYYY [at] HH:mm", locale: $siteLanguage)
        content
        parentId
        avatarUrl
      }
      totalCount
    }
  }
`;
