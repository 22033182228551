import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import { SlideDown } from "react-slidedown/lib/slidedown";

import TextArea from "../../FormControl/InputField/_textArea";
import InputFieldWithValidation from "../../FormControl/InputField/_mail";
import Email from "../../FormControl/InputField/_email";
import PrivacyCheckbox from "../../FormControl/CheckboxNew/_privacy";
import SubmitButton from "../../Buttons/Submit";
import Notice from "../../Notice";
import SuccessfullySubmit from "../../Notice/_successfullySubmit";
import { errorFooterTextComment } from "../../Notice/constantMessage";
import { sendRequest } from "../../Api_request";
import { commentDataMapping } from "../../Api_request/dataMapping";

import {
  createClassName,
  pageWithoutLocale,
  getCommentsPage,
} from "../../../helpers";
import "./style.scss";

const cn = createClassName("comments-form");

const searchElement = el => {
  return el.localName === "textarea" ? el : searchElement(el.children[0]);
};

const CommentForm = props => {
  const [isShowForms, setShowForms] = useState(false);
  const [isSend, getSending] = useState(false);
  const [isServerResponse, setServerResponse] = useState(true);
  const [page, getPathName] = useState("");
  const textareaRef = useRef(null);
  const { t } = useTranslation("forms");
  const [comentPlaceholder, setComentPlaceholder] = useState()
  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      AcceptGuides: false,
    },
  });

  useEffect(() => {
    setComentPlaceholder(t("placeholder.comment.textarea"));
  }, [])

  useEffect(() => {
    const textarea = searchElement(textareaRef.current);
    const showForms = () => setShowForms(true);

    textarea.addEventListener("focus", () => showForms());

    return textarea.removeEventListener("focus", () => showForms());
  }, []);

  useEffect(() => {
    const pathName = pageWithoutLocale(document.location.pathname);
    const setCommentPage = getCommentsPage(pathName);
    getPathName(setCommentPage);
  }, []);

  const sendMessage = (
    <SuccessfullySubmit
      nextStep={false}
      successHeaderTitle={t("comment.successTitle")}
      successFooterText={t("comment.successFooterText")}
      className={cn("message", { success: true })}
    />
  );

  const errorConnectServerMessage = (
    <Notice
      errorFooterText={errorFooterTextComment}
      className={cn("message")}
    />
  );

  const onSubmit = data => {
    const { privacyPolicy, ...dataForComments } = data;
    const mappingForComments = commentDataMapping(
      page,
      dataForComments,
      props.parentId,
      props.commentableType,
      props.commentableId,
    );
    setLoading(true);
    sendRequest(
      "comments",
      mappingForComments,
      getSending,
      setServerResponse,
      setLoading,
    );
  };

  return isSend ? (
    sendMessage
  ) : (
      <form onSubmit={handleSubmit(onSubmit)} className={props.classNameForm}>
        <div className={cn("input-field", { textarea: "wrap" })}>
          <img
            src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp"
            alt=""
            className={cn("logo")}
          />
          <div
            className={cn("input-field", { field: true, textarea: true })}
            ref={textareaRef}
          >
            <TextArea
              name="body"
              isLabel={false}
              fieldClassName={cn("input-field", { textarea: "field" })}
              errors={errors}
              register={register({ required: true })}
              isErrorState={!!errors.message}
              placeholder={comentPlaceholder}
            />
          </div>
        </div>
        {isShowForms && (
          <SlideDown className={cn("slide-down")}>
            <div className={cn("contact-fields", { show: isShowForms })}>
              <div className={cn("input-field", { field: true })}>
                <InputFieldWithValidation
                  name="authorName"
                  type="text"
                  placeholder={t("placeholder.comment.name")}
                  isLabel
                  label={t("common:name")}
                  labelPlacement="top"
                  labelClassName={cn("input-field", { label: true })}
                  className={cn("input-field")}
                  errors={errors}
                  register={register({ required: true })}
                  isErrorState={errors?.message && true}
                  isIncorrectField={false}
                />
              </div>
              <div className={cn("input-field", { field: true })}>
                <Email
                  name="authorEmail"
                  isLabel
                  label={`Email (${t("common:not-published")})`}
                  labelPlacement="top"
                  labelClassName={cn("input-field", { label: true })}
                  className={cn("input-field")}
                  errors={errors}
                  register={register}
                  isErrorState={errors?.email && true}
                />
              </div>
              <PrivacyCheckbox
                name="acceptGuides"
                errors={errors}
                register={register({ required: true })}
                isErrorState={errors?.AcceptGuides && true}
                tooltipContent={t("tooltip.comment")}
              />
              {!isServerResponse ? (
                errorConnectServerMessage
              ) : (
                  <SubmitButton
                    isLoading={isLoading}                  
                    className={cn("submit-button")}
                    buttonTextKey={t("common:send-comment")}
                  />
                )}
            </div>
          </SlideDown>
        )}
      </form>
    );
};

CommentForm.defaultProps = {
  parentId: null,
  commentableType: "Content",
  commentableId: null,
};

CommentForm.propTypes = {
  classNameForm: PropTypes.string,
  parentId: PropTypes.number,
  commentableType: PropTypes.string,
  commentableId: PropTypes.number,
};

export default CommentForm;
